@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;700&display=swap');

@font-face {
  font-family: 'fontello';
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/fontello.aa617144.eot);
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/fontello.aa617144.eot#iefix)
      format('embedded-opentype'),
    url(webpack:///mini-css-extract-plugin//_next/static/media/fontello.b3041c59.woff2) format('woff2'),
    url(webpack:///mini-css-extract-plugin//_next/static/media/fontello.f4b9d6d6.woff) format('woff'),
    url(webpack:///mini-css-extract-plugin//_next/static/media/fontello.a13f4b21.ttf)
      format('truetype'),
    url(webpack:///mini-css-extract-plugin//_next/static/media/fontello.39774739.svg#fontello)
      format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Urbanist', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.last-index {
  top: 8%;
  z-index: 1;
  transform: scale(0.84);
  transition: all 600ms linear;
}

.next-index {
  top: 16%;
  z-index: 2;
  transform: scale(0.92);
  transition: all 600ms linear;
}

.current-index {
  z-index: 3;
  top: 24%;
  transform: scale(1);
  transition: all 600ms linear;
}

.swiper-pagination-bullet {
  color: #fff;
  background: red;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #f06273;
}

.swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  width: '100%';
  height: '100%';
  /* width: '360px';
  height: '425px'; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type='password'] {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

  /* add spacing to better separate each image */
  letter-spacing: 2px;
}

.swal2-container {
  z-index: 10000000 !important;
}

.swal2-container.swal2-top,
.swal2-container.swal2-center,
.swal2-container.swal2-bottom {
  z-index: 10000000 !important;
}

.container {
  /* padding: 0 2rem; */
}

.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

@media (prefers-color-scheme: dark) {
  .card,
  .footer {
    border-color: #222;
  }

  .code {
    background: #111;
  }

  .logo img {
    filter: invert(1);
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper_slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: red;
}

.swal2-container {
  z-index: 30000;
}

/* integrationed css */

html,
body,
main {
  max-width: 100vw;
}

main section,
main.Topcontainer {
  max-width: 100%;
  overflow-x: hidden;
}

.slide {
  transition: transform 800ms ease;
}

.activeSlide {
  opacity: 1;
  transform: translate(0);
}

.nextSlide {
  opacity: 0;
  transform: translate(100%);
}

.lastSlide {
  transform: translate(-100%);
}

/* team-popup */
.poppage {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  transition: all 0.4s;
  padding: var(--pad-poppage) 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-x: hidden;
}

.poppage[class*='--fullpage'] {
  padding: 0;
}

.poppage[class*='--end'] {
  padding-bottom: 0;
  align-items: flex-end;
}

.poppage.display {
  visibility: visible;
  opacity: 1;
  z-index: 1400;
}

.poppage[class*='--end'].display .midpage {
  transform: translateY(0);
  background-color: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 20px;
}

.poppage[class*='--end'] .midpage {
  margin: 0;
  transform: translateY(300px);
  transition: transform 0.6s;
}

.poppage[class*='--fullpage'] .midpage {
  width: 100%;
  height: calc(100% - 30px);
  margin-bottom: 0;
}

.finalpage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--bg-primary);
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  border-radius: var(--rounded-normal);
}

.stopscroll {
  overflow: hidden;
}

/* swap */

.slide1 {
  transform: translate(-50%, 23%) scale(1);
  z-index: 3;
}

.slide2 {
  transform: translate(-50%, 13%) scale(0.9);
  z-index: 2;
}

.slide3 {
  transform: translate(-50%, 4%) scale(0.8);
  z-index: 1;
}
.slidenum {
  /* transform: translate(0%, 1070%) scale(1); */
  z-index: 3;
}
.container div {
  transition: transform 450ms linear;
}

/* .cont1 {
  background-color: #ffafb9;
  width: 70%;
  height: 50vh;
  position: absolute;
  left: 50%;

  border-radius: 40px;
}
.cont2 {
  background-color: #dd90be;
  width: 70%;
  height: 50vh;
  position: absolute;
  left: 50%;

  border-radius: 40px;
}
.cont3 {
  background-color: #dfa9eb;
  width: 70%;
  height: 50vh;
  position: absolute;
  left: 50%;

  border-radius: 40px;
} */
.gradient-overlay:before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    rgba(253, 253, 253, 0),
    rgba(122, 59, 134, 0.7)
  );
}

.item {
  position: relative;
  margin-bottom: 25px;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
}

.item:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.55;
}

.item--first:after {
  background-image: linear-gradient(
    to bottom,
    rgba(253, 253, 253, 0),
    rgba(122, 59, 134, 0.7)
  );
}

/* .profileimage:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0%, black 100%);
} */


/* latin-ext */
@font-face {
  font-family: '__Urbanist_13c6d8';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/8cdee4d3ed444abc-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Urbanist_13c6d8';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/01af0fc7b4278e65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Urbanist_13c6d8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8cdee4d3ed444abc-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Urbanist_13c6d8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/01af0fc7b4278e65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Urbanist_13c6d8';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8cdee4d3ed444abc-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Urbanist_13c6d8';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/01af0fc7b4278e65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Urbanist_13c6d8';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8cdee4d3ed444abc-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Urbanist_13c6d8';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/01af0fc7b4278e65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Urbanist_Fallback_13c6d8';src: local("Arial");ascent-override: 94.08%;descent-override: 24.76%;line-gap-override: 0.00%;size-adjust: 100.98%
}.__className_13c6d8 {font-family: '__Urbanist_13c6d8', '__Urbanist_Fallback_13c6d8', Helvetica, Arial, sans-serif;font-style: normal
}

